import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import { useBodyClass } from './hooks/useBodyClass';
import BB17 from './pages/BB17';
import Cycles from './pages/Cycles';
import Emergence from './pages/Emergence';
import SadFam from './pages/SadFam';
/* Theme variables */
import './theme/variables.css';





const App: React.FC = () => {
  useBodyClass("dark");
  return (<IonApp>
    <IonReactRouter>
      <Menu />
      <IonRouterOutlet animated={true}  id="main">
        <Route path="/emergence" component={Emergence} exact />
        <Route path="/cycles" component={Cycles} exact />
        <Route path="/bb17" component={BB17} exact />
        <Route path="/sadfam" component={SadFam} exact />
        <Redirect to="/emergence" />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  );
};

export default App;
