import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import XRApp from './XRApp';


Sentry.init({
    dsn: "https://91939eff2a95475b83bd9cd7982da0f2@o224096.ingest.sentry.io/5509156",
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

const supportsVR = 'getVRDisplays' in window.navigator || (('userAgent' in window.navigator ) && ((window.navigator as any).userAgent as any).includes("OculusBrowser"));

ReactDOM.render(supportsVR ? <XRApp/>:<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
