/* eslint-disable jsx-a11y/alt-text */
import { IonButtons, IonContent, IonFooter, IonHeader, IonMenuButton, IonPage, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './Page.css';


const Emergence: React.FC = () => {
    const name = "Emergence";
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonSlides>
                    <IonSlide >
                        <a href='https://store.steampowered.com/app/500470/Emergence_Fractal_Universe/'>
                            <img src='https://steamcdn-a.akamaihd.net/steam/apps/500470/ss_6b5f2a5649833a89113f727d9d79dcf176b7966a.1920x1080.jpg?t=1592365966' />
                        </a>
                    </IonSlide>
                </IonSlides>
                <IonFooter style={{ bottom: 0, position: "fixed" }}>
                    <iframe title='emergence-widget' frameBorder='0' src="https://store.steampowered.com/widget/500470/" width="100%" height="190"></iframe>
                </IonFooter>
            </IonContent>
        </IonPage >
    );
};

export default Emergence;
