import { Stars } from '@react-three/drei';
import { DefaultXRControllers, Hands, VRCanvas } from "@react-three/xr";
import React from "react";
import Wand from './components/Wand';


const XRApp = () => <VRCanvas>
    <ambientLight />
    <pointLight position={[10, 10, 10]} />
    <DefaultXRControllers />
    <Hands />
    <Stars
        radius={100} // Radius of the inner sphere (default=100)
        depth={50} // Depth of area where stars should fit (default=50)
        count={5000} // Amount of stars (default=5000)
        factor={4} // Size factor (default=4)
        saturation={0} // Saturation 0-1 (default=0)
        fade // Faded dots (default=false)
    />
    <Wand />
</VRCanvas>
export default XRApp;