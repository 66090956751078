import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader, IonImg, IonItem, IonMenuButton, IonPage, IonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './Page.css';

const Cycles: React.FC = () => {
    const name = "Cycles";
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                    <IonCardHeader>
                        Light Cycles is an iphone game released in 2014
                        Whoever can survive the longest wins.
                    </IonCardHeader>
                    <IonCardContent>
                        <IonText>
                            Try to trap your opponent by blocking off their path.
                            Use the arrow buttons to navigate.

                         
                        </IonText>
                        <IonThumbnail style={{ "--size": "400px" }} >
                            <a href='https://apps.apple.com/us/app/light-cycles/id527836065?ls=1'>
                                <IonImg src='https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/55/e3/66/55e36677-3df7-7690-7af6-f50cf38c233b/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-85-220.png/230x0w.png' />
                            </a>

                        </IonThumbnail>
                        <IonItem>
                            Privacy Policy:
                            No Data is collected by this application

                            download on the iOS app Store
                            </IonItem>

                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default Cycles;
