/* eslint-disable jsx-a11y/accessible-emoji */
import {
  IonContent,

  IonItem,

  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,

  IonMenuToggle,

  IonTitle
} from '@ionic/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Menu.css';


interface AppPage {
  url: string;
  icon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Emergence Fractal Explorer',
    url: '/emergence',
    icon: "🥽"
  },
  {
    title: 'Binaural Bass Synthesizer',
    url: '/bb17',
    icon: "🎹"
  }
];


const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" >
      <IonContent>
        <IonList id="app-list">
          <IonListHeader>
            <IonTitle>⛩️ XVII</IonTitle>
          </IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={true}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonLabel>{appPage.icon} {appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>

            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
